import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export const toastSlice = createSlice({
    name: 'toast',
    initialState: {},
    reducers: {
        showToast: (state, action) => {
            const {message, type }= action.payload;
            toast[type](message);
        }
    }
});

export const {showToast} = toastSlice.actions;
export default toastSlice.reducer;