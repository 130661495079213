import { configureStore } from '@reduxjs/toolkit';
import toastReducer from './slices/toastSlice';
import authReducer from './reducers/rootReducer';

export const store = configureStore({
  reducer: {
    toast: toastReducer,
    auth: authReducer
    // your reducers here
  },
});