import { useState, useEffect } from 'react';
import { fetchInitialData } from '../services/User/UserInformationService';

const useInfoFetchData = (userId) => {
    const [apiUInfo, setData] = useState(null);
    const [loadingUInfo, setLoading] = useState(true);
    const [errorUInfo, setError] = useState(null);

    useEffect(() => {
        fetchInitialData(userId)
            .then(response => {
                setData(response);
                setLoading(false);
            })
            .catch(err => {
                setError(err);
                setLoading(false);
            });
    }, [userId]); // Dependency array to re-run the effect if userId changes

    return { apiUInfo, loadingUInfo, errorUInfo };
};

export default useInfoFetchData;
