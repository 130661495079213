import { useParams } from "react-router-dom";
import { useState } from 'react';
import { questionsSpanish } from '../../assets/constants/AdoptFormQuestions';

export default function AdoptForm(){

    const [loggedIn, setLoggedIn] = useState(false);
    const [userId, setUserId] = useState('');
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    }

    const submitAction = async (e) => {
       /*e.preventDefault();
        try{
            //const res = await authenticate(formData);
            if(res.status == true){
                setLoggedIn(true);
                setUserId(res.usrId);
            }else{
                setLoggedIn(false);
            }
        }catch(e){
            console.error(e);
        }*/
    }

    if(loggedIn){
        const url = "/auth/user/" + userId;
        //return <Navigate to={url} />
    }

    return(
        <div className="flex justify-center items-center bg-gray-100">
            <div className="mt-[21vh] mb-8 p-3 rounded-2xl w-[89%] sm:w-[89%] md:w-[89%] flex flex-col justify-center items-center shadow bg-white">
                <h2 className="font-bold text-2xl">Adoption Form</h2>
                <p className="w-2/3 text-justify m-1 font-bold text-gray-700 text-lg">We take a lot of care and precaution before giving away one of ours, please fill out following questions and information to start procedure:</p>
                <form className="flex flex-col justify-evenly items-center h-2/3 p-4" onSubmit={submitAction}>
                    <section>
                        {questionsSpanish.map((q, idx) => (
                            <div className="w-full grow mx-2 my-4">
                                <label className="font-bold ">{q.question}</label>
                                {q.type === "text"?
                                    <div className="flex bg-white min-w-[200px] p-2 items-center justify-center border-2 border-gray-200 rounded-md focus:outline-red-500">
                                        <input name="email" className="w-full focus:outline-none mx-3" type="text" placeholder="..." />
                                    </div>:
                                    <div className="flex flex-col w-full justify-start items-start bg-white min-w-[200px] p-2 border-2 border-gray-200 rounded-md">
                                        {q.options.map((option, idx) => (
                                            <label className="flex">    
                                                <input name="" type="checkbox" value={option} className="focus:outline-none mx-3"/>
                                                {option}
                                            </label>
                                        ))}
                                    </div>}
                            </div>
                        ))}
                    </section>

                    <div className="flex flex-row justify-around items-center w-full">
                        <input className="px-5 py-2 text-md font-bold text-white bg-blue-500 rounded-xl border-2 border-blue-500 hover:bg-blue-700 ease-in-out duration-300 cursor-pointer mt-4" type="submit" value="Sumbit"/>
                    </div>
                </form>
            </div>
        </div>
    )
}