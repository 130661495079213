function WorkWith() {
    return (
        <>
            <section id="WorkWith" className="h-auto flex p-3 w-full sm:flex-col flex-col" style={{ backgroundImage: "url(/images/fondo_amarillo.jpg)" }}>
                <h1 className="font-bold sm:text-2xl sm:my-3 text-xl xlg:text-5xl underline text-center">Our Partners</h1>
                <div className="flex sm:flex-col w-full justify-evenly items-center sm:justify-center">
                    <img className="sm:w-1/2 md:w-1/2 my-7" src="/images/FAH.png" alt="FAH"/>
                    <img className="sm:w-1/2 md:w-1/2" src="/images/adoptmx.png" alt="AdoptMX"/>
                </div>
            </section>
        </>
    );
}

export default WorkWith;