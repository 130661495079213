import { FaInstagram, FaFacebook, FaTwitter, FaTiktok, FaWhatsapp } from 'react-icons/fa';
import ContactForm from '../components/ContactForm';

function Footer() {
  return (
    <footer className="flex p5 bg-gray-800" >
      <div className="flex-wrap max-[799px]:flex-col min-[800px]:flex-row justify-evenly w-full items-center m-5">
        
      <ContactForm />

        <div className="my-3 flex flex-col justify-center items-center max-[799px]:w-full">
          <h1 className="max-[799px]:text-md min-[800px]:text-2xl max-[799px]:flex text-white font-bold">Contact Info</h1>
          <ul className="flex flex-col justify-center items-center text-white">
            <li className="max-[799px]:text-xs min-[800px]:text-xl">contacto@paws2home.org</li>
            <li className="text-base">55 5555 5555</li>
          </ul>
        </div>

        <div className="flex flex-col items-center min-[800px]:w-1/2 max-[799px]:w-full m-4">
          <h1 className="max-[799px]:text-md min-[800px]:text-3xl text-white font-bold">Síguenos en nuestras redes</h1>
          <ul className="flex space-x-4 max-[799px]:flex-wrap text-white">
            <li className="min-[800px]:text-2xl max-[799px]:text-lg">
              <a href="https://www.instagram.com/paws2home.mx/?igshid=MzRlODBiNWFlZA%3D%3D" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </a>
            </li>
            <li className="min-[800px]:text-2xl max-[799px]:text-lg"><FaFacebook /></li>
            <li className="min-[800px]:text-2xl max-[799px]:text-lg"><FaTwitter /></li>
            <li className="min-[800px]:text-2xl max-[799px]:text-lg">
              <a href="https://www.tiktok.com/@paws2home.org" target="_blank" rel="noopener noreferrer">
                <FaTiktok />
              </a>
            </li>
            <li className="min-[800px]:text-2xl max-[799px]:text-lg"><FaWhatsapp /></li>
          </ul>
        </div>

      </div>
    </footer>
  );
}

export default Footer;  

