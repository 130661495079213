import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import {RescueFetchData} from '../../hooks/rescuePawHook';
import { FaMapPin, FaVenusMars, FaClock } from 'react-icons/fa';
import { Link as RouterLink } from "react-router-dom";
import { getEnvProps } from "../../utils/utils";
export default function PawDetails({ pid }){
    const {pawId} = useParams();
    const [selectedPhoto, setSelectedPhoto] = useState(1);
    const {pawInfo, load, error} = RescueFetchData.FetchById(useParams().pid);
    const selectPhoto = (e) => {
        setSelectedPhoto(e);
    }
    const [imagesMap, setImagesMap] = useState([]);
    const props = getEnvProps();
    const selectedSmall = "overflow-hidden flex items-center justify-center mt-2 xxlg:w-[200px] xxlg:h-[280px] border-2 min-h-[110px] min-w-[110px] rounded-xl p-1 bg-white cursor-pointer ease-in-out duration-300 hover:shadow hover:border-2 hover:border-slate-200";
    const nonSelectedSmall = "overflow-hidden flex justify-center mt-2 xxlg:w-[200px] xxlg:h-[280px] min-h-[110px] min-w-[110px] rounded-xl cursor-pointer ease-in-out duration-300";

    useEffect(() => {
        if(pawInfo && pawInfo.imgNames){
            setImagesMap(pawInfo.imgNames);
        }
    }, [pawInfo, load]);
      
    return(<>
        <div className="bg-slate-200 flex justify-center items-center xxlg:w-full">
            <div className="mt-[21vh] min-h-[70vh] flex-col w-[97vw] p-3 flex-wrap justify-evenly max-w-[1980px]">
                
                <div className="flex rounded-3xl w-[50%] justify-center md:flex-wrap lg:flex-wrap sm:w-full md:w-full">
                        <div className="flex flex-col mr-4 w-1/3">
                        {
                            imagesMap.map((obj, idx) => (
                                <div className={obj.id === selectedPhoto? selectedSmall : nonSelectedSmall} >
                                    <img className="h-full rounded-md" src={props.fileSystem + obj.url} key={obj.id} alt="Dog or Cat Image" onClick={() => selectPhoto(obj.id)}/>
                                </div>                                                       
                            ))
                        }
                        </div>  
                        <div className="flex bg-white/[.6] backdrop-blur-sm rounded-3xl shadow justify-center items-center grow p-3 lg:w-full">
                            {imagesMap.length > 0? 
                                <img src={props.fileSystem + imagesMap[selectedPhoto - 1].url} alt="Dog or Cat Image" className="rounded-xl w-3/4 xlg:object-fit"/>
                            : <></>}                     
                        </div>    
                </div>
                { pawInfo?
                <div className="flex flex-col justify-start max-w-[40%] min-w-[30%] sm:w-full">
                    <div className="flex flex-col justify-center bg-white rounded-xl p-2 shadow-lg">
                        <div className="flex flex-col mb-5 ">
                            <h3 className="mb-3 text-xl font-bold">{pawInfo.pawName}</h3>
                            <div className="flex mx-2 items-center">
                                <FaClock className="mr-2 text-lg"/>
                                <label className="mb-3 text-lg font-bold">{pawInfo.pawBirthYear}</label>
                            </div>
                            {/*<div className="flex mx-2 items-center">
                                <FaVenusMars className="mr-2 text-lg"/>
                                <label className="mb-3 text-lg font-bold"></label>
                            </div>*/}
                            <div className="flex mx-2 items-center">
                                <FaMapPin className="mr-3 text-lg"/>
                                <label className="mb-3 text-lg font-bold">{pawInfo.residenceCity + ", " + pawInfo.residenceCountry}</label>
                            </div>
                            <div className="flex mx-2 items-center">
                                <label className="mb-3 text-lg font-bold text-gray-700 mr-2">Rescued on: </label>
                                <label className="mb-3 text-lg font-bold text-gray-700">{pawInfo.arrivedOn}</label>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-xl px-3 font-bold">My Story:</h2>
                            <p className="p-3 text-justify">
                                {pawInfo.pawDescription}
                            </p>
                        </div>
                        <div className="flex flex-center justify-center my-4">
                            <div className="bg-white">
                                <RouterLink to="/donations">
                                    <button className="bg-orange-600 rounded-xl text-white p-1 font-semibold text-lg hover:bg-orange-700 ease-in-out duration-300 cursor-pointer m-1 px-2">Make a donation </button>
                                </RouterLink>
                            </div>
                            <div className="bg-white">
                                <Link to={`/adopt/details/${pid}`} >
                                <button className="bg-orange-600 rounded-xl text-white p-1 font-semibold text-lg hover:bg-orange-700 ease-in-out duration-300 cursor-pointer m-1 px-2">I want to Adopt </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                :<></>
                }
            </div>
        </div>
    </>);
}