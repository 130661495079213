import useIntroFetchData from "../../hooks/userIntroductionHook";
import { useEffect, useState } from "react";
import { userIntroApiService } from '../../services/User/UserIntroductionService';
import { useDispatch } from 'react-redux';
import { showToast } from '../../redux/slices/toastSlice';

export default function UserIntro({ uid, userAuth }){

    const {apiUserIntro, loadingUserIntro, errorUserInfo} = useIntroFetchData(uid);
    const [userWhy, setUserWhy] = useState(null);
    const [ userIntro, setUserIntro ] = useState(null);
    const dispatch = useDispatch();
    const [ isOwner, setIsOwner ] = useState(userAuth);



    const triggerToastActionFail = (message) => {
        dispatch(showToast({message: message, type: "error"}));
    }

    const triggerToastActionSuccess = (message) => {
        dispatch(showToast({ message: message, type:"success"}));
    }
    
    useEffect(() => {
        if(apiUserIntro){
            setUserWhy(apiUserIntro.whyP2H);
            setUserIntro(apiUserIntro.introduction);
        }
    }, [apiUserIntro])
    
    
    if (loadingUserIntro) return <div>Loading. . .</div>
    if (errorUserInfo) return <div>Error Ocurred . . .</div>

    const handleIntroUpdate = async(e) => {
        e.preventDefault();

        const data = {
            userId: uid,
            introduction: userIntro,
            whyP2H: userWhy
        };

        try{
            const res = await userIntroApiService.updateEntry(data);
            if(res.status === 200){
                triggerToastActionSuccess(res.data)
            }else{
                triggerToastActionFail(res.data);
            }
        }catch(e){
            console.error(e);
        }
    }
    return(
        <div className="w-full flex flex-col justify-center items-center bg-white rounded-2xl shadow p-3 sm:w-[89%]">
            <form className="w-full" onSubmit={handleIntroUpdate}>
                <div className="w-full">
                    <h2 className="text-xl font-semibold w-full mt-3">Introduction</h2>
                    { isOwner
                        ?   <div class="mt-2">
                                <textarea value={userIntro} onChange={(e) => setUserIntro(e.target.value)} id="intro" name="intro" rows="3" className="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                <p class="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p>
                            </div>
                        : <p> {userIntro} </p>
                    }  
                </div>
            
                <div className="w-full">
                    <h2 className="text-xl font-semibold w-full mt-6">Why did you join P2H?</h2>
                    { isOwner
                    ?       <div class="mt-2">
                                <textarea value={userWhy} onChange={(e) => setUserWhy(e.target.value)} id="why" name="why" rows="3" className="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                    : <p> {userWhy} </p>
                    }
                </div>  
                { isOwner 
                        ?   <div className="w-full flex justify-center items-center py-2">
                            <input value="Actualizar" type="submit" className="px-2 py-1 rounded-xl text-white bg-blue-500 hover:bg-blue-700 ease-in-out duration-300 cursor-pointer"/> 
                            </div>
                        : <></>
                }
            </form>
        </div>
    );
}