import PayPalDonateButton from "../components/PayPalDonateButton";
import { memo } from "react";

const MemoPayPalDonateButton = memo(PayPalDonateButton);

export default function DonatePage() {

    return (
      <div className="h-[87vh] border-2 flex justify-center items-center w-[100vw]" style={{backgroundImage: `url(/images/profile.png)`}}> 

      <div className="flex flex-col w-[89vw] md:flex-col sm:flex-col items-center justify-center rounded-xl overflow-hidden">

        <div className="flex flex-col p-3 lg:w-[78%] items-center justify-center">
            <div className="m-2">
              <h1 className="text-white font-extrabold text-5xl">Apoyo Paws 2 Home </h1>
            </div>
            <div className="m-2 font-bold text-lg">
              <p>
                ¡Para continuar desarrollando y ayudando a tantos seres sin voz necesitamos de tú apoyo!
              </p>
            </div>
            <div className="m-2 font-bold text-lg">
              <p>Puedes realizar donaciones por solo una ocasión o hacerlas concurrentemente de forma automática.</p>
            </div>
        </div>
                
        <div className="flex flex-col items-center justify-center shadow border-white border-2 p-3 rounded-md hover:bg-slate-100 ease-in-out transition">
          <MemoPayPalDonateButton />
        </div>

      </div>
      </div>
    );
}
