import UserInfo from './UserInfo';
import UserIntro from './UserIntroduction';
import UserAdoptions from './UserAdoptions';
import UserRescues from './UserRescues';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { validateAuthentication } from '../../services/AuthService';
import { useAppContext } from '../../context/AppContext';


export default function UserPage(){

    const {loggedUser, setLoggedUser} = useAppContext()
    const [test, setTest] = useState(false);
    const [isAuth, setIsAuth] = useState(validateAuthentication());
    
    const handleToastTrigger = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
        });
    }

    return(
        <div className="w-full flex justify-center items-center bg-slate-100 min-h-[110vh]">
            
            <div className="w-full h-full max-w-[1980px] flex justify-around flex-wrap p-3 sm:flex-col sm:py-[15vh] sm:justify-center sm:items-center">
                <ToastContainer 
                    position="top-right"
                    autoClose={3500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"   
                />
                <UserInfo 
                    uid={loggedUser.userId} 
                    userAuth={loggedUser.logged}
                />
                <div className="flex flex-col w-2/3 sm:w-full sm:items-center">
                    <UserIntro 
                        uid={loggedUser.userId}
                        userAuth={loggedUser.logged}
                    />
                    <UserAdoptions 
                        uid={loggedUser.userId}
                        userAuth={loggedUser.logged}
                    />
                    <UserRescues
                        uid={loggedUser.userId}
                        userAuth={loggedUser.logged}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userState: state.authReducer.user
    }
}
