import { createContext, useContext, useState, useEffect } from 'react';
import { get } from '../utils/utils';

const AppContext = createContext();

export function useAppContext() {
    return useContext(AppContext);
}

export const AppProvider = ({ children }) => {
    const [user, setUser] = useState({ email: null, name: null, pppath: null });
    const [loggedUser, setLoggedUser] = useState(() => {
        
        const loggedUserLocal = get('p2hlus');
        if(loggedUserLocal)
            return JSON.parse(loggedUserLocal);
        else
            return {userId: null, logged: false, token:null};
    });
    const [loggedUserInfo, setLoggedUserInfo] = useState({name: null, lastname: null, pppic: null});

    const value = {
        user,
        setUser,
        loggedUser, 
        setLoggedUser
    };

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    );
};