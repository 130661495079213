export const questionsSpanish = [
    {
        question: "Nombre Completo",
        type: "text"
    },
    {
        question: "Edad:",
        type: "text"
    },
    {
        question: "Dirección:",
        type: "text"
    },
    {
        question: "Red Social:",
        type: "text",
        extra: "Donde subirás fotos de tu adoptado"
    },
    {
        question: "Ocupación:",
        type: "text"
    },
    {
        question: "Correo:",
        type: "text"
    },
    {
        question: "¿Con quén vives?",
        type: "multiple",
        options:[
            "Solo", "Familia", "Amigos"
        ]
    },
    {
        question: "Si aplica, por favor proporciona los nombres y edades de con quiénes vives.",
        type: "text"
    },
    {
        question: "¿Vives en casa o departamento? ¿Eres dueño o rentas?",
        type: "multiple",
        options:[
            "En casa, soy dueño", "En casa, rento", "En departamento, soy dueño", "En departamento, rento."
        ]
    },
    {
        question: "¿Tienes mascotas?",
        type: "multiple",
        options:[
            "Si", "No"
        ]
    },
    {
        question: "En caso de tener mascotas, compartenos cuantas y su edad. ",
        type: "text"
    },
    {
        question: "¿Qué mascota tienes?",
        type: "text"
    },
    {
        question: "¿Has tenido mascotas antes?",
        type: "multiple",
        options: [
            "Si", "No"
        ]
    },
    {
        question: "En caso de si haber tenido o tener actualmente, platicanos un poco sobre ella, ¿Perro o gato? ¿Edad? ¿Cuánto tiempo estuvo o ha estado contigo? ¿En caso de fallecimiento, cómo fue? ¿Cómo és o era su rutina diaria?",
        type: "text",
        large: true
    },
    {
        question: "Tus mascotas actuales o pasadas, ¿Están o estuvieron esterilizadas?",
        type: "multiple",
        options: [
            "Si", "No"
        ]
    },
    {
        question: "Paws2home, por protocolo entrega a todos sus rescatados esterilizados (salvo que sea muy cachorro para ser esterilizado aún y sea adoptado, en cuyo caso la esterilización corresponde a los nuevo dueños y pedimos que firmen una carta compromiso que establece que se comprometen a esterilizarlo en cuanto tenga la edad, ¿Estas de acuerdo con esto?",
        type: "multiple",
        options: [
            "Si, estoy de acuerdo con que se me entregue mi adoptado esterilizado y en caso de no ser asi, me comprometo a esterilizarlo yo como nuevo dueño",
            "No, estoy de acuerdo con que se me entregue mi adoptado esterilizado y en caso de ser asi, no me comprometo a esterilizarlo yo como nuevo dueño"
        ]
    },
    {
        question: "¿Cuál consideras que es el presupuesto promedio que puedes gastar mensualmente en tu adoptado? (sin tomar en cuenta veterinarios)",
        type:"multiple",
        options:[
            "$2,000 MXN", "$2,500 MXN", "Más de $3,000 MXN"
        ]
    },
    {
        question: "¿Cuanto tiempo pasaría el adoptado sin compañía humana?",
        type:"multiple",
        options:[
            "2-3 hrs al día", "3-5 hrs al día", "Más de 5 hrs al día"
        ]
    },
    {
        question: "¿Donde dormirá el adoptado?",
        type:"multiple",
        options:[
            "Dentro de algún cuarto, mío, familiar o amigo", "Dentro de la casa, cocina, sala o área de lavado", "Fuera de la casa, jardín o patio"
        ]
    },
    {
        question: "¿Cuántas veces saldrá a pasear el adoptado?",
        type:"multiple",
        options:[
            "1 vez al día, 30 min", "2 veces al día", "3 veces al día, 30 mins", "Más de 3 veces al día"
        ]
    },
    {
        question: "¿Cada cuanto se bañará el adoptado?",
        type:"multiple",
        options:[
            "1 vez al mes", "2 veces al mes", "3 o más veces al mes"
        ]
    },
    {
        question: "¿Cuántas veces al día comerá?",
        type:"multiple",
        options:[
            "1 vez", "2 veces", "3 veces o más"
        ]
    },
    {
        question: "Si sales de vacaciones, ¿Con quién o donde dejarías al adoptado?",
        type:"text"
    },
    {
        question: "Si te cambias de casa o país, ¿Qué harías con el adoptado?",
        type:"text"
    },
    {
        question: "Si el adoptado resulta ser más jugueton o desastroso de lo que esperabas, ¿Qué harías?",
        type:"text"
    },
    {
        question: "Al ser la gran mayoría perritos mestizos, no siempre sabemos el tamaño o peso final, si tu adoptado es más grande o pesado de lo que esperabas, ¿Qué harías?",
        type:"text"
    },
    {
        question: "¿Qué te motivo a adoptar?",
        type:"text",
    },
    {
        question: "¿Por qué quieres adoptar a el/ella en especial?",
        type:"text"
    },
    {
        question: "¿Por qué consideras que eres la persona ideal para adoptarlo/la?",
        type:"text"
    },
    {
        question: "¿Toda la familia (en caso de haberla) está 100% de acuerdo con esta adopción?",
        type:"text"
    },
    {
        question: "¿Cómo es su rutina familiar los fines de semana?",
        type:"text",
    },
];