export default function UserAdoptions({ uid }){
    
    const dummyAdoptions= [{}];

    return(
        <div className="border-t-2 border-t-slate-200 p-3 mt-3 flex flex-col justify-center">
            <h2 className="text-2xl font-semibold">Adoptions</h2>
            {dummyAdoptions.count > 0?
            <div></div> :
            <div className="bg-white w-full rounded-3xl shadow flex justify-center items-center mt-3 p-5" >
                <label className="text-gray-700 font-semibold text-lg">This user has not made any rescue yet.</label>
            </div>    
            }
        </div>
    )
}