
import { PROD_ENV_PROPS, DEV_ENV_PROPS } from "./Constants";

// Session control
export const set = (key, value, json) => {
    localStorage.setItem(key, json? JSON.stringify(value) : value);
}

export const get = (key) => {
    return localStorage.getItem(key);
}

export const validateToken = (token) => {
    // TODO validate token with backend
    return true;
}

export const drop = (key) => {
    localStorage.removeItem(key);
}

export const getEnvProps = () => {
    if(process.env.REACT_APP_ENV === 'development') return DEV_ENV_PROPS;
    if(process.env.REACT_APP_ENV === 'production') return PROD_ENV_PROPS;
}
