export default function Notfound() {
    return(
        <div className="h-[87vh] border-2 flex flex-col justify-center items-center w-[100vw] ">
            <div className="flex justify-center w-full">
            <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 24 24"><path d="M21 10c1.103 0 2 .897 2 2s-.897 2-2 2h-18c-1.103 0-2-.897-2-2s.897-2 2-2h18zm3 2c0-1.657-1.343-3-3-3h-18c-1.657 0-3 1.343-3 3s1.343 3 3 3h18c1.657 0 3-1.343 3-3zm-9-1h-12c-.552 0-1 .448-1 1s.448 1 1 1h12c.552 0 1-.448 1-1s-.448-1-1-1z"/></svg>
            </div>
            <h1 className="font-bold">
                Sorry for the inconveniences, we are working on this for you
            </h1>
        </div>
    );
}