import DatePicker from 'react-datepicker';

const MyDatePickerImpl = ({selectedDate, onDatechange, name}) => {
    return (
        <div className="w-full text-right flex justify-end items-end">

        <DatePicker 
            className="text-right bg-transparent"
            selected={selectedDate}
            onChange={(date) => {onDatechange(date); } }   
            name={name} 
            />
        </div>
    );
}

export default MyDatePickerImpl;