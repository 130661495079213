import axios from 'axios';
import { getAccessToken } from '../AuthService';
import axiosInstance from '../AxiosInstance';
import { getEnvProps } from '../../utils/utils';

const props = getEnvProps();

    const fetchInitialDataIntro = async(userId) => {
 
    const getInfoUrl = props.apiUrl + `user/intro/get?userId=${userId}`;
    const res = await axiosInstance.get(getInfoUrl)
    
    if(res.data != null && res.status === 200){
        return res.data;
    }else{
        console.error(res.error);
        return null;
    }

};

const createEntry = (newData) => {
};

const updateEntry = async(formData) => {
    const updateUInfoUrl = props.apiUrl + 'user/intro/save';
    return await axiosInstance.put(updateUInfoUrl, formData);
};

const deleteEntry = (id) => {
};

export const userIntroApiService = {
    fetchInitialDataIntro,
    createEntry,
    updateEntry,
    deleteEntry,
};