import { useEffect } from "react";

export default function PayPalDonateButton() {

    useEffect(() => {
        // Dynamically load the PayPal donation script
          window.PayPal.Donation.Button({
            env: 'production',
            hosted_button_id: '298J9YYF3VP9Q',
            image: {
              src: 'https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif',
              alt: 'Donar con el botón PayPal',
              title: 'PayPal - The safer, easier way to pay online!',
            }
          }).render('#donate-button');

          return () => {
            // Proper cleanup if possible
            const donateButton = document.querySelector('#donate-button');
            if(donateButton){
              donateButton.innerHTML = '';
            }

          };
  
      }, []);
    
      return (
        <div id="donate-button-container" className="flex items-center justify-center">
          <div id="donate-button"></div>
        </div>
      );
}