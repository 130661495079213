import axios from 'axios';
import { getAccessToken } from './AuthService';
import { useSelector } from 'react-redux';

const accToken = getAccessToken();
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  config => {
    // Add token or other modifications to the config
    const token = accToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;