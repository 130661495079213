import HomePage from '../pages/HomePage';
import PawsCatalog from '../pages/PawsCatalog';
import DonatePage from '../pages/DonatePage';
import Notfound from '../pages/Notfound';
import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage';
import UserPage from '../pages/user/UserPage';
import PawDetails from '../pages/paw/PawDetails';
import AdoptForm from '../pages/paw/AdoptForm';

const Routes = [
    {
        path:"/",
        exact: true,
        component: HomePage
    },
    {
        path:"/home",
        exact: true,
        component: HomePage
    },
    {
        path:"/adopt",
        exact: true,
        component: PawsCatalog
    },
    {
        path:"/adopta",
        exact: true,
        component: PawsCatalog
    },
    {
        path:"/donations",
        exact: true,
        component: DonatePage
    },
    {
        path:"/login",
        exact:true,
        component: LoginPage
    },
    {
        path:"/register",
        exact:true,
        component: RegisterPage
    },
    {
        path:"/auth/user/:uid",
        exact: true,
        component: UserPage
    },
    {
        path:"/paw/details/:pid",
        exact: true,
        component: PawDetails
    },
    {
        path:"/adopt/details/:pid",
        exact: true,
        component: AdoptForm
    },
    {
        path:"/*",
        exact: true,
        component: Notfound
    }
];

export default Routes;