import { getAccessToken } from '../AuthService';
import { getEmailFromToken } from '../../utils/TokenUtils';
import axiosInstance from '../AxiosInstance';
import { getEnvProps } from '../../utils/utils';

const props = getEnvProps();
export const fetchInitialData = async (userId) => {
    const token = getAccessToken();
    const email = getEmailFromToken(token);
    const getInfoUrl = props.apiUrl + `user/info/get?userId=${userId}&userEmail=${email}`;
    const res = await axiosInstance.get(getInfoUrl)
    
    if(res.data != null && res.status === 200){
        return res.data;
    }else{
        console.error(res.error);
        return null;
    }
}

const updateProfilePicture = async (formData) => {
    const updatePpUrl = props.apiUrl + 'file/upload';
    return await axiosInstance.post(updatePpUrl, formData);
}

const updateUserInformation = async (formData) => {
    const updateUInfoUrl = props.apiUrl + 'user/info/save';
    return await axiosInstance.put(updateUInfoUrl, formData)
}

const createEntry = (newData) => {
};

const updateEntry = (id, updatedData) => {
};

const deleteEntry = (id) => {
};


export const userInfoApiService = {
  createEntry,
  updateEntry,
  deleteEntry,
  updateProfilePicture,
  updateUserInformation
};