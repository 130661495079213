import HSection1 from '../components/HomeSection1';
import SearchBar from '../components/SearchBar';
import AboutUs from '../components/AboutUs';
import Adoptionwork from '../components/AdoptionWork';
import WorkWith from '../components/WorkWith';
import AboutMe from '../components/AboutMe';

function HomePage(){
    return(
        <>
        <div>
            <HSection1 />
            <SearchBar />
            <AboutUs />
            <AboutMe />
            <Adoptionwork />
            <WorkWith />
        </div>
        </>
    )
} 

export default HomePage;