export const PROD_ENV_PROPS = {
    base_url: "https://mantasw.com:8888/api/auth",
    token_name: "P2H_AUTH_TOKEN",
    fileSystem: "https://mantasw.com:8888/images/",
    fileUploadUrl: "file/upload",
    apiUrl: "https://mantasw.com:8888/api/",
    frontUrl: "https://paws2home.org"
}

export const DEV_ENV_PROPS = {
    base_url: "http://localhost:8888/api/auth",
    apiUrl: "http://localhost:8888/api/",
    token_name: "P2H_AUTH_TOKEN",
    fileUploadUrl: "file/upload",
    fileSystem: "http://localhost:8888/images/",
    frontUrl: "http://localhost:3000"
}