import axios from 'axios';
import { getEnvProps } from '../utils/utils';


const props = getEnvProps();

const PawAdoptionForm = async () => {
    const rescueApiUrl = props.apiUrl + "paw/getAll";
    const res = await axios.get(rescueApiUrl)
    
    if(res.data != null && res.status === 200){
        console.log(res);
        return res.data;
    }else{
        console.error(res.error);
        return null;
    }
}


const GenericInquiry = () => {

}

export const EmailService = {
    PawAdoptionForm,
    GenericInquiry
};
