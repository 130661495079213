import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getEnvProps } from '../utils/utils';

export async function register(data){

    const props = getEnvProps();
    const endpoint = props.base_url + "/register";
    var resp;

    await axios.post(endpoint, data)
    .then(res => {
        if(res.status === 200 && res.data.token != null){
            resp = true;
        }else{
            resp = false;
        }
    })
    .catch(err => {
        console.error(err);
        alert("Sorry, please contact our support Team. Register")
    });
    
    return resp;
}

const handleAccessTokenComp = () => {
    //const userPayload = useSelector(state => state.auth.user);

}

export async function checkIfOwner(data){
    const base_url = getEnvProps();
    const endpoint = base_url + "/authenticate";
    var resp;
    var _usrId;
    var _token;

    await axios.post(endpoint, data)
    .then(res => {
        if(res.data != null && res.data.token != null && res.status === 200){
            handleAccessToken(res.data.token);
            resp = true;
            _usrId = res.data.userId;
            _token = res.data.token;
        }else{
            console.error(res);
            alert("Something went wrong, try again.");
            resp = false;
        }
    })
    .catch(err => {
        console.error(err); 
        resp = false;
    });
    return {
        status: resp,
        usrId: _usrId,
        token: _token
    };
}

export async function authenticate(data){
    const props = getEnvProps();
    const base_url = props.base_url
    const endpoint = base_url + "/authenticate";
    var resp;
    var _usrId;
    var _token;

    await axios.post(endpoint, data)
    .then(res => {
        if(res.data != null && res.data.token != null && res.status === 200){
            handleAccessToken(res.data.token);
            resp = true;
            _usrId = res.data.userId;
            _token = res.data.token;
        }else{
            console.error(res);
            alert("Something went wrong, try again.");
            resp = false;
        }
    })
    .catch(err => {
        console.error(err); 
        resp = false;
    });
    return {
        status: resp,
        usrId: _usrId,
        token: _token
    };
}

export function handleAccessToken(token){
    // save token into cookie
    const cookieName = "P2H_AUTH_TOKEN";
    const cookieExpiryDate = getExpiryDate(token);
    document.cookie = `${cookieName}=${token}; ${cookieExpiryDate}; SameSite=Lax; path="/"; Secure `;
}

const getExpiryDate = (token) => {
    const decodedToken = getDecodedToken(token).exp;
    return `expires= ${new Date(decodedToken * 1000).toUTCString}`;
}

const getDecodedToken = (token) => {
    const tokenSplit = token.split('.');
    return JSON.parse(atob(tokenSplit[1]));
}

export function getAccessToken(){

    const props = getEnvProps();
    const allCookies = document.cookie;
    if (allCookies.length > 0){
        const cookies = allCookies.split("; ");
        const regex = new RegExp(`^${props.token_name}=(.*)$`);
        const token = cookies.find(item => regex.test(item.trim()));
        // Check if token is not undefined before splitting
        if (token !== undefined) {
            const temp = token.split("=");
            return temp[1];
        } else {
            // Handle the case where token is undefined
            return null;
        }
    }else{
        return null;
    }
}

export function validateAuthentication(){
    const token = getAccessToken();
    if (token != null)
        return true;
    else
        return false;
}

export function getEmailFromToken(){
    const decodedToken = getDecodedToken(getAccessToken());
    
    if (decodedToken != null){
        console.log("DecodedToken: ", decodedToken);
        return decodedToken.sub;
    }else{
        return "Not Found.";        
    }   
}