import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import SwiperComponent from '../components/Swiper';
import {RescueFetchData} from '../hooks/rescuePawHook';
import PawThumbnail from './paw/PawThumbnail';


function PawsCatalog(){

    const {rescueInfo, loading, error} = RescueFetchData.FetchDataAll(null);
    const [ screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    });

    const handleScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleScreenWidth);

    const renderRescueInfo = (toggleDesc) => {

        if(rescueInfo.length > 0){
            return (
                <div className="flex p-2 flex-wrap">
                    {rescueInfo.map((item) => (
                        <PawThumbnail pawObj={item} descToggle={toggleDesc}></PawThumbnail>
                    ))}
                </div>)
            }else{
                return <div className="flex border-2 bg-white p-2 rounded-xl border-blue-200 justify-center"> 
                    <span className="text-lg font-bold">No animals have been registered yet...</span> 
                </div> 
            }
    }

    if (loading) {return <div className="flex w-full items-center justify-center"><img src="/images/Iphone-spinner-2.gif"/></div>}
    if (error) {return <div>Error Ocurred . . .</div>}

    return(
        <div className="w-[100vw] flex justify-center items-center p-3" style={{backgroundImage: `url(/images/profile.png)`}}>
          <div className="w-full flex flex-wrap justify-evenly items-center mt-[15vh] xlg:mb-5 xlg:max-w-[1980px]">

                <div className="w-full flex flex-wrap sm:w-full justify-center items-center sm:justify-center md:justify-center items-center shadow-2xl bg-white/[.3] p-1 rounded-2xl xlg:w-[89%] xlg:h-[89vh] backdrop-blur-md">
                    <div className="flex flex-wrap items-center justify-center w-full min-w-[290px] p-3">
                            <h2 className="w-full text-center text-3xl m-3 font-bold text-orange-600">All</h2>
                                <div className="flex justify-evenly items-center w-full">
                                    { renderRescueInfo(true) }
                                </div>
                        </div>
                    </div>    
            </div>
        </div> 
    );
}

export default PawsCatalog;