import { Link } from "react-router-dom";

const primaryLogo = () => {
    return "w-[21vw] ml-11 items-center my-3";
}

const secondaryLogo = () => {
    return "h-full w-[21vw] ml-11 ease-in-out duration-300 items-center my-3";
}

function Logo(props){
    return(
        <>
            <div className={props.navScroll? secondaryLogo() : primaryLogo()}>
                <Link to="/home">
                    <img src="/images/Logo_v1.svg" alt="Paws2Home" className="" />
                </Link>
            </div>
        </>
    )
}

export default Logo;