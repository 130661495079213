import { FaLock, FaEnvelope } from 'react-icons/fa';
import { Navigate, Link as RouterLink } from "react-router-dom";
import { authenticate } from '../services/AuthService';
import { useState } from 'react';
import { loginSuccess, logout } from '../redux/actions/actions';
import { useDispatch } from 'react-redux';
import { useAppContext } from '../context/AppContext';
import { set } from '../utils/utils';

export default function LoginPage(){

    const {loggedUser, setLoggedUser} = useAppContext();

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    }


    const submitAction = async (e) => {
        e.preventDefault();
        try{
            const res = await authenticate(formData);
            if(res.status === true){
                dispatch(loginSuccess({
                    'userId': res.usrId,
                    'token': res.token
                }));

                setLoggedUser({userId: res.usrId, token: res.token, logged: true});
                set('p2hlus', {userId: res.usrId, token: res.token, logged: true}, true);
            }else{
                setLoggedUser({userId: null, token: null, logged: false});
            }
        }catch(e){
            console.error(e);
        }
    }

    if(loggedUser.logged){
        const url = "/auth/user/" + loggedUser.userId;
        return <Navigate to={url} />
    }

    return(
        <>
        <div className="h-[100vh] flex justify-center items-center bg-gray-100">
            <div className="border-2 border-gray-200 p-3 rounded-2xl h-1/2 w-1/2 sm:w-[89%] md:w-[89%] flex flex-col justify-center items-center shadow bg-white">
                <h1 className="font-bold text-2xl">Log In</h1>
                <form className="flex flex-col justify-evenly items-center h-2/3 p-4" onSubmit={submitAction}>
                    <div className="">
                        <label className="font-semibold #6c2d2d">Email:</label>
                        <div className="flex bg-white p-2 items-center justify-center border-2 border-gray-200 rounded-md focus:outline-red-500">
                            <FaEnvelope />
                            <input name="email" className="focus:outline-none mx-3" type="text" placeholder="Email..." onChange={handleInputChange} />
                        </div>
                    </div>
                    <div>
                        <label className="font-semibold">Password:</label>
                        <div className="flex bg-white p-2 items-center justify-center border-2 border-gray-200 rounded-md">
                            <FaLock />
                            <input name="password" className="focus:outline-none mx-3" type="password" placeholder="Password..." onChange={handleInputChange}/>
                        </div>
                    </div>
                    <div className="flex flex-row justify-around items-center w-full">
                        <RouterLink className="font-semibold text-blue-500 rounded-xl border-2 border-blue-500 px-5 py-2 hover:text-white hover:bg-blue-700 ease-in-out duration-300 cursor-pointer mt-4" to="/register">Register</RouterLink>
                        <input className="px-5 py-2 text-md font-semibold text-white bg-blue-500 rounded-xl border-2 border-blue-500 hover:bg-blue-700 ease-in-out duration-300 cursor-pointer mt-4" type="submit" value="Enter"/>
                    </div>
                </form>
                <label className="text-gray-800 w-full text-center">Click <b>Register</b> if you don't have an account.</label>
            </div>
        </div>
        </>
    );
}