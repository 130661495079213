function Adoptionwork(){
  return (
     <>
      <section id="AboutMe" className="flex justify-center overflow-hidden sm:flex-col sm:items-center" style={{
         backgroundImage: "url(/images/fondorosa.jpg)"
      }}>
        <div className="lg:max-w-[55vw] max-w[100%] flex flex-col items-center text-xl h-[90vh] sm:h-auto sm:w-[89%]">
          <h1 className="text-5xl m-10 sm:mb-2 font-semibold max-[600px]:text-xl max-[600px]:w-[100%]">About Me</h1>
          <div className="flex justify-center w-1/3 sm:w-1/2 overflow-hidden rounded-2xl h-full items-center sm:mb-5" style={{ marginTop: '35px' }}>
            <img src="/images/adoptionwork.jpeg" alt="adoption woks :)" className="object-contain rounded-3xl w-full sm:max-w-[360px] max-w-[500px]"  />
          </div>
          <div className="sm:mb-5" style={{ maxWidth: '650px' }}>
                <p className="text-xl text-justify" >
                Soy una rescatista de animales desde hace un par de años pero hasta ahorita no he tenido la oportunidad de poder tener mi propio refugio, 
                    por lo que cada que rescato un animalito lo cuido en mi casa hasta que el encuentro una familia amorosa y comprometida.
                    <br/><br/> 
                    Con el paso de los años me fui dando cuenta que la situación de los animales en México es sumamente preocupante, 
                    ya que más del 70% de ellos viven en condiciones de calle, maltrato o abandono y de forma muy deplorable y triste... y la verdad es que por más que quiera, 
                    me es imposible ayudar a todos, no solo por el espacio y los gastos, pero porque se necesitan manos! Es por esto que tuve la idea de crear Paws2Home, 
                    una plataforma en donde pudiera unir a muchos rescatistas como yo y a muchas familias alrededor del mundo que quieren dar segundas oportunidades. 
                    <br/><br/> 
                    La verdad, nunca pensé que en serio podría crear esta red de apoyo, consistente en buscar siempre la mejor familia para cada animalito, 
                    pero verlo materializándose poco a poco me llena el corazón de felicidad, mi vocación siempre ha sido cuidar y proteger a los animales y espero que poco a poco, entre todos, 
                    logremos un mundo lleno amor, cariño y respeto hacia los animales! 
                    Recuerden que el amor, no tiene precio 
                   😊</p>
                    </div>
          </div>
      </section>
    </>
  );
}
export default Adoptionwork;