import { getAccessToken } from '../AuthService';
import { getEmailFromToken } from '../../utils/TokenUtils';
import axiosInstance from '../AxiosInstance';
import axios from 'axios';
import { getEnvProps } from '../../utils/utils';

const props = getEnvProps();

const fetchInitialData = async (userId) => {
    const token = getAccessToken();
    const email = getEmailFromToken(token);
    const rescueApiUrl = props.apiUrl + `paw/get?userId=${userId}`;
    const res = await axiosInstance.get(rescueApiUrl)
    
    if(res.data != null && res.status === 200){
        return res.data;
    }else{
        console.error(res.error);
        return null;
    }
}

const fetchInitialDataAll = async () => {
    const rescueApiUrl = props.apiUrl + "paw/getAll";
    const res = await axios.get(rescueApiUrl)
    
    if(res.data != null && res.status === 200){
        return res.data;
    }else{
        return null;
    }
}

const fetchPawById = async (id) => {
    const rescueApiUrl = props.apiUrl + `paw/getP?pid=${id}`;
    const res = await axios.get(rescueApiUrl)

    if(res.data != null && res.status === 200){
        return res.data;
    }else{
        console.error(res.error);
        return null;
    }
}

const fetchUpdate = async (userId) => {
    const getPawsUrl = props.apiUrl + 'paw/getP?userId=' + userId;
    return await axiosInstance.get(getPawsUrl); 
}

const createEntry = async (formData) => {
    const updateUInfoUrl = props.apiUrl + 'paw/save';
    return await axiosInstance.put(updateUInfoUrl, formData)
};


export const rescuesService = {
    fetchInitialData,
    fetchInitialDataAll,
    createEntry,
    fetchPawById,
    fetchUpdate
};