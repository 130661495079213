import { Routes, Route } from "react-router-dom";
import  RoutesDetail  from "./utils/Routes";
import Nav from "./components/Nav";
import Footer from './components/Footer';
import { AppProvider } from "./context/AppContext";


function App() {
  return (
    <AppProvider>
      <div className="">
      <Nav />
      <Routes>
          {RoutesDetail.map((route, index) => (
            <Route key={index} path={route.path} Component={route.component} />
          ))}
      </Routes>
      <Footer />
      </div>
    </AppProvider>
  );
}

export default App;