import { FaPaw } from "react-icons/fa"; 
import { Link } from "react-router-dom";
import { FaDog, FaCat } from "react-icons/fa";
import { useState } from 'react';

export default function SearchBar() {
    
    const [dogSelected, setDogSelected] = useState(false);
    const [catSelected, setCatSelected] = useState(false);
    const styleFalse = "sm:text-4xl md:text-5xl lg:text-4xl xlg:text-5xl text-gray-400 ease-in-out duration-300 cursor-pointer";
    const styleTrue = "sm:text-4xl md:text-5xl lg:text-4xl xlg:text-5xl ease-in-out duration-300 cursor-pointer";

    const dogHandleSelection = () => {
        setDogSelected(!dogSelected);
    }
    
    const catHandleSelection = () => {
        setCatSelected(!catSelected);
    }

    return (
        <section className="flex justify-center w-full">
            <div className="flex flex-wrap items-center sm:justify-center md:justify-center lg:justify-evenly justify-evenly p-5 lg:w-full xlg:w-full max-w-[1800px]">  
                <div className="flex sm:w-[55%] sm:justify-center md:w-full md:justify-center md:m-1 lg:">
                    <h1 className="text-gray-700 font-bold sm:text-2xl md:text-3xl text-3xl">Find & Adopt{window.innerWidth < 800? ":" : ""}</h1>
                </div>
                <div className="flex sm:w-[40%] sm:justify-center md:justify-center md:flex-col mx-2">
                    <div className="flex flex-col items-center sm:mx-2 md:my-2 justify-center lg:mx-1 mx-4">
                        <FaDog onClick={dogHandleSelection} className={dogSelected? styleTrue : styleFalse}/>
                        <label className="text-orange-500 font-bold text-md sm:hidden">Dog</label>
                    </div>
                    <div className="flex flex-col items-center sm:mx-2 md:my-2 justify-center lg:mx-1 mx-4">
                        <FaCat onClick={catHandleSelection} className={catSelected? styleTrue : styleFalse}/>
                        <label className="text-orange-500 font-bold text-md sm:hidden">Cat</label>
                    </div>
                </div>
                <div className="sm:hidden flex md:justify-center md:ml-2 md:items-center ">
                    <div className="flex flex-col mx-5">
                        <label className="font-bold text-lg">Country:</label>
                        <select className="bg-white border-2 border-gray-200 rounded-xl md:p-2 lg:p-2 xlg:p-3">
                            <option className="text-lg text-red-400">Mexico</option>
                            <option className="text-lg text-red-400">USA</option>
                            <option className="text-lg text-red-400">Canada</option>
                            <option className="text-lg text-red-400">Italy</option>
                        </select>
                    </ div>
                    <div className="flex flex-col mx-5">
                        <label className="font-bold text-lg">City:</label>
                        <select className="bg-white border-2 border-gray-200 rounded-xl md:p-2 lg:p-2 xlg:p-3">
                            <option className="text-lg text-red-400">CDMX</option>
                            <option className="text-lg text-red-400">New York</option>
                            <option className="text-lg text-red-400">Toronto</option>
                            <option className="text-lg text-red-400">Rome</option>
                        </select>
                    </div>
                    <div className="flex flex-col mx-5">
                        <label className="font-bold text-lg">Size:</label>
                        <select className="bg-white  border-2 border-gray-200 rounded-xl md:p-2 lg:p-2 xlg:p-3">
                            <option className="text-lg text-orange-500">Small</option>
                            <option className="text-lg text-orange-500">Medium</option>
                            <option className="text-lg text-orange-500">Large</option>
                        </select>
                    </div>
                </div>
                <div className="flex flex-col md:justify-center sm:justify-center sm:w-full sm:mt-3">
                        <Link className="" to="/adopt">
                            <button className="flex items-center justify-center px-3 rounded-full bg-orange-500 text-white font-semibold text-lg ease-in-out duration-300 hover:bg-orange-600">
                                    Find a friend
                                    <FaPaw className= "m-3"/>
                            </button>
                        </Link>
                        <Link className="sm:hidden">Or <span className="hover:underline ease-in-out duration-300">see all</span></Link>
                </div>
            </div>
        </section>
    );
}


