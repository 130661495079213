import { useState, useEffect } from 'react';
import { userIntroApiService } from '../services/User/UserIntroductionService';

const useIntroFetchData = (userId) => {
    const [apiUserIntro, setData] = useState(null);
    const [loadingUserIntro, setLoading] = useState(true);
    const [errorUserInfo, setError] = useState(null);

    useEffect(() => {
        userIntroApiService.fetchInitialDataIntro(userId)
            .then(response => {
                setData(response);
                setLoading(false);
            })
            .catch(err => {
                setError(err);
                setLoading(false);
            });
    }, [userId]); // Dependency array to re-run the effect if userId changes

    return { apiUserIntro, loadingUserIntro, errorUserInfo };
};

export default useIntroFetchData;
