
function HSection1(){
    return (
        <section className="h-[100vh]">
          <div className="h-full flex flex-col justify-center items-center bg-cover bg-right bg-no-repeat md:bg-right lg:bg-right xlg:bg-right" 
               style={{backgroundImage: `url(/images/profile.png)`}}>
            <div className="flex flex-col justify-center md:items-start md:w-full w-5/6 sm:w-full h-full p-8 max-w-xxlg">
              <div className="flex justify-start sm:text-center xlg:text-left w-full">
                <h2 className="text-white sm:text-3xl md:text-5xl lg:text-7xl xlg:text-6xl font-bold">
                  Don't buy, adopt!
                </h2>
              </div>
              <div className="flex sm:justify-end md:justify-center lg:justify-center xlg:justify-center w-full lg:justify-start">
                <h2 className="text-white sm:text-3xl md:text-4xl lg:text-7xl xlg:text-6xl font-bold">
                  True love is priceless
                </h2>
              </div>
            </div>
          </div>
        </section>
      );
}

export default HSection1;