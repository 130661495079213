import { getEnvProps } from "../../utils/utils";

const props = getEnvProps();

export default function PawThumbnail({pawObj, descToggle}){



    return(
        <a href={`${props.frontUrl}/paw/details/` + pawObj.id}>
            <div className="p-3 flex flex-col m-1 sm:w-full bg-slate-50 rounded-xl shadow" >
                <div class="flex items-center justify-center">
                    <img className="h-[250px] w-full object-cover object-center rounded-lg shadow" src={`${props.filesystem}/images/` + pawObj.img_1}></img>
                </div>

                <div className="flex justify-start">
                        <h3 className="w-full text-lg font-bold">{pawObj.pawName}</h3>
                </div>
                <div>
                    <label>{pawObj.residenceCity}, {pawObj.residenceCountry}</label>
                </div>
                    <label><b className="text-gray-800">Birth: </b>{pawObj.pawBirthYear}</label>
                {descToggle?
                <div>
                    <p className="text-justify">
                        {pawObj.pawDescription}
                    </p>
                </div>
                : null}
            </div>
        </a>
    );

}