import { userInfoApiService } from "../../services/User/UserInformationService";
import useInfoFetchData from "../../hooks/userInformationHook";
import { useState } from "react";
import MyDatePickerImpl from '../../components/MyDatePickerImpl';
import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showToast } from '../../redux/slices/toastSlice';


export default function UserInfo({ uid, userAuth }){
    
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState();
    const [ ppIsSet, setAllowSubmitPp] = useState(false);
    const {apiUInfo, loadingUInfo, errorUInfo} = useInfoFetchData(uid);
    const {ppInfo, loadingPpnfo, errorPpnfo} = useInfoFetchData(uid);
    const [profilePic, setProfilePic] = useState(null);
    const [userPpPath, setUserPpPath] = useState(null);
    const userIdProp = useParams().uid; 
    const [ isOwner, setIsOwner ] = useState(userAuth);
    
    const [userInfo, setUserInfo] = useState({
        userId: useParams().uid,
        birthDate: new Date(),
        maritalStatus: {},
        occupation: '',
        pets: '',
        cityOfResidence: '',
        countryOfResidence: '',
        contactEmail: '',
        sex: '',
        pp: ''
    });

    useEffect(() => {
        if(apiUInfo){
            setUserInfo({
                userId: apiUInfo.userId || userIdProp,
                birthDate: new Date(apiUInfo.birthDate) || '',
                maritalStatus: apiUInfo.maritalStatus || '',
                occupation: apiUInfo.occupation || '',
                pets: apiUInfo.pets || '',
                cityOfResidence: apiUInfo.cityOfResidence || '',
                countryOfResidence: apiUInfo.countryOfResidence || '',
                contactEmail: apiUInfo.contactEmail || '',
                sex: apiUInfo.sex || '',
                pp: apiUInfo.ppPath || ''
            });
            setSelectedDate(apiUInfo.birthDate);
            setUserPpPath("https://mantasw.com:8888/images/" + apiUInfo.ppPath);
        }
    }, [apiUInfo], selectedDate);

    if (loadingUInfo) {return <div className="flex w-full items-center justify-center"><img src="/images/Iphone-spinner-2.gif"/></div>}
    if (errorUInfo) {return <div>Error Ocurred . . .</div>}
    
    const handleInput = (e) => {
        const {name, value} = e.target;
        setUserInfo({ ...userInfo, [name]: value});
    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setUserInfo(prevUserInfo => ({ ...prevUserInfo, birthDate: date}));
    }

    const handlePpSubmit = async (e) => {
        e.preventDefault();
        if(profilePic){

            const formData = new FormData();
            formData.append('file', profilePic);
            formData.append('userId', uid);
            formData.append('description', `profilePic_key`);
    
            try{
                const res = await userInfoApiService.updateProfilePicture(formData);
                if(res.status === 200){
                    setUserPpPath("https://mantasw.com:8888/images/" + res.data);
                    triggerToastActionSuccess("Successfully Updated Profile Picture");
                }else{
                    triggerToastActionFail(res.data);
                }
            }catch(e){
                console.error(e);
            }
        
        }else{
            console.error("No file found. Try Again.")
        }
    }

    const updateUInfo = async(e) => {
        e.preventDefault();
        try{
            const res = await userInfoApiService.updateUserInformation(userInfo);
            if(res.status === 200){
                triggerToastActionSuccess(res.data)
            }else{
                triggerToastActionFail(res.data);
            }
        }catch(e){
            console.error(e);
        }
    }

    const handleFileChange = (e) => {
        setAllowSubmitPp(true);
        setProfilePic( e.target.files[0]);
    }

    const triggerToastActionFail = (message) => {
        dispatch(showToast({message: message, type: "error"}));
    }


    const triggerToastActionSuccess = (message) => {
        dispatch(showToast({ message: message, type:"success"}));
    }

    return(
        <div className="w-1/4 flex h-full justify-start flex-col sm:w-[89%]">
            <div className="w-full rounded-3xl shadow bg-white flex flex-col justify-center items-center p-3">
                <img className="w-[255px] h-[255px] rounded-full border-2 border-gray-500 shadow object-cover" src={userPpPath}/>
                { isOwner 
                    ?   <div className="py-2 flex w-full justify-evenly">
                        <form onSubmit={handlePpSubmit}>
                            <input type="file" id="prof-pic-upload" onChange={handleFileChange} className="hidden"/>
                            <label className="font-bold cursor-pointer" for="prof-pic-upload">Editar...</label>
                            { ppIsSet 
                            ? <input value="Guardar" type="submit" className="px-2 py-1 rounded-xl text-white bg-blue-500 hover:bg-blue-700 ease-in-out duration-300 cursor-pointer"/> : <></>}
                        </form>
                        </div>
                    :<></>}
            </div>
            <div className="mt-2 rounded-3xl flex flex-col justify-between">
                <h2 className="font-bold text-l">{apiUInfo.fullName.toUpperCase()}</h2>
                <form onSubmit={updateUInfo}>
                    <div className="flex justify-between w-full border-b-2 border-b-gray-200 p-1 mb-3">
                        <label className="font-semibold text-lg">{apiUInfo.username}</label>
                        <label className="text-blue-600 text-md font-semibold">{apiUInfo.verified_status}</label>
                    </div>
                    <div className="flex justify-between w-full p-1">
                        <label className="font-semibold text-md max-w-1/3">Country:</label>
                        { isOwner
                            ? <input
                            type="text" 
                            name="countryOfResidence"
                            className="font-semibold text-md bg-transparent text-end h-full p-1 w-auto"    
                            value={userInfo.countryOfResidence} 
                            onChange={handleInput} placeholder="Country of Residence..."/>
                            : <label>{apiUInfo.residence}</label>
                        }
                    </div>
                    <div className="flex justify-between w-full p-1">
                        <label className="font-semibold text-md max-w-1/3">City:</label>
                        { isOwner
                            ? <input
                            type="text"
                            name="cityOfResidence"
                            className="font-semibold text-md bg-transparent text-end h-full p-1 w-auto"    
                            value={userInfo.cityOfResidence} 
                            onChange={handleInput} placeholder="City of Residence..."/>
                            : <label>{apiUInfo.residence}</label>
                        }
                    </div>
                    <div className="flex justify-between w-full p-1">
                        <label className="font-semibold text-md">Sex:</label>
                        { isOwner
                            ? <input
                            type="text" 
                            name="sex"
                            className="font-semibold text-md bg-transparent text-end h-full p-1 w-auto"    
                            value={userInfo.sex} 
                            onChange={handleInput} placeholder="Male, Female, other..."/>
                            : <label>{apiUInfo.sex}</label>
                        }
                    </div>
                    <div className="flex justify-between w-full p-1">
                        <label className="font-semibold text-md">Birthdate: </label>
                        { isOwner
                            ? <MyDatePickerImpl
                                selectedDate={userInfo.birthDate}
                                onDatechange={handleDateChange}
                                name="birthDate"
                            />
                            : <label>{apiUInfo.birthdate}</label>
                        }
                    </div>
                    <div className="flex justify-between w-full  p-1">
                        <label className="font-semibold text-md">Relationship Status: </label>
                        { isOwner
                            ? <input
                            type="text" 
                            name="maritalStatus"
                            className="font-semibold text-md bg-transparent text-end h-full p-1 w-auto"    
                            value={userInfo.maritalStatus} 
                            onChange={handleInput} placeholder="Relationship Status..."/>
                            : <label>{apiUInfo.martialStatus}</label>
                        }
                    </div>
                    <div className="flex justify-between w-full p-1">
                        <label className="font-semibold text-md">Occupation:</label>
                        { isOwner
                            ? <input
                            type="text" 
                            name="occupation"
                            className="font-semibold text-md bg-transparent text-end h-full p-1 w-auto"    
                            value={userInfo.occupation} 
                            onChange={handleInput} placeholder="Occupation..."/>
                            : <label>{apiUInfo.occupation}</label>
                        }
                    </div>
                    <div className="flex justify-between w-full p-1">
                        <label className="font-semibold text-md">Pets:</label>
                        { isOwner
                            ? <input
                            type="text" 
                            name="pets"
                            className="font-semibold text-md bg-transparent text-end h-full p-1 w-auto"    
                            value={userInfo.pets} 
                            onChange={handleInput} placeholder="Pets..."/>
                            : <label>{apiUInfo.pets}</label>
                        }
                    </div>
                    <div className="flex justify-between w-full p-1">
                        <label className="font-semibold text-md">Contact Email:</label>
                        { isOwner
                            ? <input
                            type="text" 
                            name="contactEmail"
                            className="font-semibold text-md bg-transparent text-end h-full p-1 w-auto"    
                            value={userInfo.contactEmail}
                            onChange={handleInput} placeholder="Contact Email..."/>
                            : <label>{apiUInfo.email}</label>
                        }
                    </div>
                    { isOwner 
                        ?   <div className="w-full flex justify-center items-center py-2">
                            <input value="Actualizar" type="submit" className="px-2 py-1 rounded-xl text-white bg-blue-500 hover:bg-blue-700 ease-in-out duration-300 cursor-pointer"/> 
                            </div>
                        : <></>}
                </form>
            </div>
        </div>
    );
}