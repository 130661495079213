import { FaHeart } from "react-icons/fa";

function Adoptionwork(){
  return (
     <>
      <section id="AdoptionWorks" className="py-5 px-2 flex justify-center overflow-hidden" style={{
        backgroundImage: "url(/images/ADOPTW.png)"
      }}>
        <div className="sm:flex-col xlg:w-full flex xlg:justify-center">
          <div className="p-2 w-1/2 sm:w-full flex-col justify-around items-center">
            <h1 className="sm:text-3xl md:text-3xl lg:text-5xl xlg:text-5xl font-bold">How adoption Works</h1>
            <ul className="w-full text-lg leading-relaxed text-justify sm:text-base md:text-lg lg:text-xl xlg:text-2xl">
              <li className="my-2 xlg:my-5 flex items-center">
                <FaHeart className="mx-2 text-sm xlg:text-sm text-white" />Find a pet
              </li>
              <li className="my-2 xlg:my-5 flex items-center">
                <FaHeart className="mx-2 text-sm xlg:text-sm text-red-600" />Fill out our form&nbsp;<a href="/" className="underline font-bold italic">here</a>
              </li>
              <li className="my-2 xlg:my-5 flex items-center">
                <FaHeart className="mx-2 text-sm xlg:text-sm text-white" />Wait to be contacted
              </li>
              <li className="my-2 xlg:my-5 flex items-center">
                <FaHeart className="mx-2 text-sm xlg:text-sm text-red-600" />Interview with rescuer
              </li>
              <li className="my-2 xlg:my-5 flex items-center">
                <FaHeart className="mx-2 text-sm xlg:text-sm text-white" />Bring it home
              </li>
            </ul>
          </div>
          <div className="sm:w-full flex items-center justify-center">
            <img className="object-fit object-center sm:w-1/4 xlg:w-1/2" src="/images/1.gif" alt="adoption woks :)" />
          </div>
        </div>
      </section>
    </>
  );
}
export default Adoptionwork;