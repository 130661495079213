import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { FaBars, FaTimes, FaUser } from "react-icons/fa";
import Logo from "../assets/logo/Logo";
import { useAppContext } from "../context/AppContext";
import LogOff from "./LogOff";
import { useEffect } from "react";


const menuItems = [
  {
    title: "Home",
    link: "/home",
    isScrollLink: false,
    displayIfUserLogged: true,
    logOut: false
  },
  {
    title: "About Us",
    link: "aboutUsSection",
    isScrollLink: true,
    displayIfUserLogged: true,
    logOut: false
  },
  {
    title: "Shop",
    link: "/shop",
    displayIfUserLogged: true,
    logOut: false
  },
  {
    title: "Donate",
    link: "/donations",
    displayIfUserLogged: true,
    logOut: false
  },
  { 
    displayIfUserLogged: false,
    title: "Log In",
    link: "/login"
  },
  {
    displayIfUserLogged: false,
    title: "Register",
    link: "/register",
  },
  {
    displayIfUserLogged: true,
    profileMe: true,
    title: "Me",
    link: "/auth/user"
  },
  {
    logOut: true,
  }
];

const Nav = () => {

  
  const {loggedUser, setLoggedUser, loggedUserInfo, loggedUserIno} = useAppContext();
  
  const [menuOpen, setOpen] = useState(false);
  const [navScroll, setNavScroll] = useState(false);
  
  const handleMenu = () => {
    setOpen((prev) => !prev);
  };
  
  const changeNavBar = () => {
    if(window.scrollY > 60 ){
      setNavScroll(true);
    }else{
      setNavScroll(false);
    }
  };
  
  const primaryNav = () => {
    return 'fixed top-0 w-[100vw] h-[13vh] bg-transparent overflow flex justify-between flex-row ease-in-out duration-300';
  }
  
  const secondaryNav = () => {
    return 'fixed top-0 w-[100vw] h-[8vh] overflow flex justify-between flex-row bg-slate-100 shadow ease-in-out duration-300';
  }
  
  window.addEventListener('scroll', changeNavBar);
  
  
  const renderMenuItems = (item) => {
    if(item.isScrollLink && item.displayIfUserLogged) {
      return (<ScrollLink to={item.link} smooth={true} duration={1000}>
        {item.title}
      </ScrollLink>)
    } else if(item.displayIfUserLogged && !item.scrollink && !item.profileMe){
    
      return (<RouterLink to={item.link}>{item.title}</RouterLink>);
    
    } else if (item.displayIfUserLogged && loggedUser.logged && item.profileMe) {
      return (
        <RouterLink to={item.link + "/" + loggedUser.userId}>
        <label className="cursor-pointer flex flex-row"> <FaUser className="mr-1"/> {item.title}</label>
        </RouterLink>
      )
    
    } else if (!loggedUser.logged && !item.displayIfUserLogged && !item.logOut){
      return (<RouterLink to={item.link}>{item.title}</RouterLink>);
    
    } else if(loggedUser.logged && item.logOut){
      return <LogOff />
    
    }
  }

  return (
    <>
      <div className={navScroll? secondaryNav() : primaryNav()}>
        <Logo state={navScroll} />
        <nav className="h-full flex-stretch flex mr-8 items-center sm:flex-col sm:items-end sm:justify-center">
          <ul className="flex justify-evenly text-slate-600 w-full items-center sm:hidden md:hidden lg:hidden">
            {menuItems.map((menuItem, index) => (
              <li key={index} className="m-2 text-2xl cursor-pointer ease-in-out duration-300 hover:text-black hover:underline">
                {renderMenuItems(menuItem)}               
              </li>
            ))}
          </ul>
          <button type="button" onClick={handleMenu}>
            {menuOpen ? <FaTimes className="text-2xl" /> : <FaBars className="xlg:hidden text-2xl" />}
          </button>
          {menuOpen && (
            <div id="navDropdownMenu" className="w-full h-[38vh] bg-[#f9f9f9] fixed top-0 left-0 z-50 overflow-y-auto shadow-lg">
              <button type="button" className="absolute top-4 right-4 text-2xl text-gray-800" onClick={handleMenu}>
                <FaTimes />
              </button>
              <ul className="flex flex-col justify-center items-center mt-8 ease-in-out duration-300 font-bold text-gray-800">
                {menuItems.map((menuItem, index) => (
                  <li key={index} className="my-3 text-xl hover:text-gray-500 hover:underline w-3/4 border-b-2 border-b-gray-300">
                    {menuItem.isScrollLink ? (
                      <ScrollLink to={menuItem.link} smooth={true} duration={1000}>
                        {menuItem.title}
                      </ScrollLink>
                    ) : (
                      <RouterLink to={menuItem.link}>{menuItem.title}</RouterLink>
                      )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </nav>
        </div>
    </>
  );
};

export default Nav;