function AboutUs() {
  return (
    <>
      <section id="aboutUsSection" className="flex justify-center overflow-hidden" style={{
        backgroundImage: "url(/images/fondo_amarilloclaro.jpg)"
      }}>
        <div className="flex md:w-[89%] lg:w-[89%] xlg:w-full flex-wrap p-3 md:justify-center">
          <div className="flex w-[45%] sm:w-full m-1 justify-center items-center">
            <img src="/images/aboutus.jpeg" alt="About Me" className="object-contain rounded-3xl w-full sm:max-w-[360px] max-w-[500px]" />
          </div>
          <div className="flex flex-col m-1 sm:w-full justify-center xlg:max-w-[650px] items-center my-5 max-w-[1280px]">
            <h1 className="text-3xl w-full font-bold">About Us</h1>
            <p className="text-xl text-justify">
            
              "Paws 2 Home" es una iniciativa conmovedora y noble que surgió de un deseo profundo de ofrecer segundas oportunidades a aquellos seres inocentes y cariñosos que a menudo se quedan sin voz en nuestra sociedad: los perros abandonados y desamparados. Este proyecto no es solo una misión, sino una pasión impulsada por la compasión y el amor hacia estos animales fieles. Al reconocer la triste realidad de innumerables perros que enfrentan la vida en refugios o en las calles, "Paws 2 Home" busca ser un faro de esperanza, brindando un puente amoroso entre estos adorables caninos y familias amorosas que anhelan agregar un nuevo miembro a su hogar.
                  <br /><br/>
              Cada perro tiene su propia historia, a menudo marcada por el abandono, pero "Paws 2 Home" se esfuerza por reescribir esos relatos con finales felices. Al facilitar adopciones responsables y conscientes, este proyecto no solo salva vidas, sino que también crea conexiones inquebrantables de amor y lealtad. Es un testimonio de que cada perro merece una segunda oportunidad para encontrar un hogar lleno de amor, seguridad y felicidad.
                  <br /><br/>
              Con cada adopción exitosa, "Paws 2 Home" no solo transforma la vida de un perro, sino que también enriquece la de las familias adoptantes, demostrando que la bondad y la compasión pueden marcar una diferencia real. Este proyecto es un recordatorio de que cuando extendemos nuestras manos y corazones a los que no tienen voz, podemos cambiar el mundo, una vida peluda a la vez.
                  <br /><br/>
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUs;