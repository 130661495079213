import React, { useState } from 'react';

function ContactForm() {
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    comentario: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    alert("Formulario no enviado");
  };

  return (
    <section id="contactForm" className="bg-gray-900 text-white py-10 px-5">
  <div className="max-w-4xl mx-auto">
    <h2 className="text-3xl font-bold text-center mb-6">Send us a Message</h2>
    <form className="space-y-4">
      <div className="flex flex-wrap -mx-2">
        <div className="w-full md:w-1/2 px-2">
          <label for="name" className="block text-sm font-medium">Name</label>
          <input type="text" id="name" name="name" required className="mt-1 block w-full p-2 bg-gray-800 border border-gray-700 rounded-lg focus:ring focus:ring-indigo-500" />
        </div>
        <div className="w-full md:w-1/2 px-2 mt-4 md:mt-0">
          <label for="email" className="block text-sm font-medium">Email</label>
          <input type="email" id="email" name="email" required className="mt-1 block w-full p-2 bg-gray-800 border border-gray-700 rounded-lg focus:ring focus:ring-indigo-500" />
        </div>
      </div>
      <div>
        <label for="comment" className="block text-sm font-medium">Comment</label>
        <textarea id="comment" name="comment" rows="4" required className="mt-1 block w-full p-2 bg-gray-800 border border-gray-700 rounded-lg focus:ring focus:ring-indigo-500"></textarea>
      </div>
      <div className="text-center">
        <button type="submit" className="mt-4 px-6 py-2 bg-indigo-600 text-white font-semibold rounded-lg hover:bg-indigo-700 focus:ring focus:ring-indigo-500">Send</button>
      </div>
    </form>
  </div>
</section>
  );
}

export default ContactForm;