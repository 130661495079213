import { FaUser, FaLock, FaEnvelope, FaCircle } from 'react-icons/fa';
import { useState } from 'react';
import { register } from '../services/AuthService';
import { Link } from 'react-router-dom';
import { EmailService } from '../services/EmailService';

export default function RegisterPage(){
    const [status, setStatus] = useState(false);
    
    const [formData, setFormData] = useState({
        firstname: '',
        email: '',
        password: '',
        lastname: '',
        middlename:''
    });

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    }

    const submitAction = async (e) => {
        try{
            e.preventDefault();
            setStatus(await register(formData));
            cleanForm();
        }catch(e){
            console.error(e);
        }
    }

    const cleanForm = () => {
        setFormData({
            firstname: '',
            email: '',
            password: '',
            lastname: '',
            middlename:''
        });
    }

    return(
        <>
        <div className="h-[100vh] flex justify-center items-center bg-gray-100">
            <div className="mt-[5vh] border-2 border-gray-200 p-3 rounded-2xl w-1/2 sm:w-[89%] md:w-[89%] flex flex-col justify-center items-center shadow bg-white">
            {status === true ? 
            <>
                <h2 className="font-semibold text-xl m-8 text-black-700">Thank you for joining Paws 2 Home!</h2>
                <label className="font-semibold #6c2d2d">Please login with your credentials.</label>
                <Link to="/login">
                    <button className="px-5 py-2 text-md font-semibold text-white bg-blue-500 rounded-xl border-2 border-blue-500 hover:bg-blue-700 ease-in-out duration-300 cursor-pointer mt-4">
                        Login
                    </button>
                </Link>
            </>
            :<>
                <h1 className="font-bold text-3xl mt-3 text-gray-800">Register</h1>
                <p className="font-semibold text-gray-700">For our's and everyone's safety we require the following information:</p>
                <form className="flex flex-col mt-3 justify-evenly items-center p-4 w-2/3 sm:w-full md:w-full lg:w-full" onSubmit={submitAction}>
                    <div className="w-full my-2">
                        <label className="font-semibold #6c2d2d">Name:</label>
                        <div className="flex bg-white p-2 items-center justify-center border-2 border-gray-200 rounded-md  w-full">
                            <FaUser />
                            <input required name="firstname" className="focus:outline-none mx-3 w-full" type="text" placeholder="Name..." onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="w-full my-2">
                        <label className="font-semibold #6c2d2d">Middlename:<span className="text-xs text-gray-400 font-semibold"> (Optional)</span></label>
                        <div className="flex bg-white p-2 items-center justify-center border-2 border-gray-200 rounded-md w-full">
                            <input name="middlename" className="focus:outline-none mx-3  w-full" type="text" placeholder="Middlename..." onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="w-full my-2">
                        <label className="font-semibold #6c2d2d">Lastname:</label>
                        <div className="flex bg-white p-2 items-center justify-center border-2 border-gray-200 rounded-md w-full">
                            <input required name="lastname" className="focus:outline-none mx-3  w-full" type="text" placeholder="Lastname..." onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="w-full my-2">
                        <label className="font-semibold #6c2d2d">Email:</label>
                        <div className="flex bg-white p-2 items-center justify-center border-2 border-gray-200 rounded-md w-full">
                            <FaEnvelope />
                            <input required name="email" className="focus:outline-none mx-3  w-full" type="email" placeholder="Email..." onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="w-full my-2">
                        <label className="font-semibold #6c2d2d">Password:</label>
                        <div className="flex bg-white p-2 items-center justify-center border-2 border-gray-200 rounded-md w-full">
                            <FaLock />
                            <input required name="password" className="focus:outline-none mx-3  w-full" type="password" placeholder="Password..." onChange={handleInputChange}/>
                        </div>
                    </div>
                    <div className="flex flex-row justify-around items-center w-full">
                        <input className="px-5 py-2 text-md font-semibold text-white bg-blue-500 rounded-xl border-2 border-blue-500 hover:bg-blue-700 ease-in-out duration-300 cursor-pointer mt-4" type="submit" value="Submit"/>
                    </div>
                </form>
            </>
                }
            </div>
        </div>
        </>
    );
}