import { FaDoorOpen } from "react-icons/fa";
import { useAppContext } from "../context/AppContext";
import { drop } from "../utils/utils";
import { useNavigate } from "react-router-dom";


const LogOff = () => {

    const {setLoggedUser} = useAppContext();

    const navigate = useNavigate();

    const handleLogOff = () => {
        setLoggedUser( {userId: null, logged: false, token:null});
        drop("p2hlus", null);
        navigate("/home");
    }

    return (
        <>
            <FaDoorOpen className="ml-2" onClick={ handleLogOff}/>
        </>
    );

};


export default LogOff;