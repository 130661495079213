import { useState, useEffect } from 'react';
import { rescuesService } from '../services/User/RescueService';

const FetchDataUser = (userId) => {
    const [rescueInfo, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        rescuesService.fetchInitialData(userId)
        .then(response => {
            setData(response);
            setLoading(false);
        })
        .catch(err => {
            setError(err);
            setLoading(false);
        });
    }, [userId]); // Dependency array to re-run the effect if userId changes
    
    return { rescueInfo, loading, error };
};

const FetchDataAll = (s) => {
    const [rescueInfo, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        rescuesService.fetchInitialDataAll()
        .then(response => {
            setData(response);
            setLoading(false);
        })
        .catch(err => {
            setError(err);
            setLoading(false);
        });
    }, [s]);
    return { rescueInfo, loading, error };
};

const FetchById = (id) => {
    const [pawInfo, setPawInfo] = useState(null);
    const [load, setLoad] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        rescuesService.fetchPawById(id)
        .then(response => {
            setPawInfo(response);
            setLoad(false);
        })
        .catch(err => {
            setError(err);
            setLoad(false);
        });
    }, [id]);
    return { pawInfo, load, error };

}

export const RescueFetchData = {
    FetchDataUser,
    FetchById,
    FetchDataAll
};
