export const uploadPawFormSpanish = {
    q: {
        question: "Nombre de mascota",
        type: "text"
    },
    q2:{
        question: "Por favor, escribe una breve descripción o historia.",
        type: "text"
    },
    q3:{
        question: "Aproximadamente, ¿Cuando nació?",
        type: "date"
    },
    q4:{
        question: "¿En qué país vive?",
        type: "select",
        options:[
            "México", "Argentina", "Canada", "Estados Unidos", "Venezuela", "Colombia", "Honduras", "Chile", "España", "Suiza", "Francia", "Italia"
        ],
        name: "countryResidence"
    },
    q5:{
        question: "¿En qué estado vive?",
        type: "select",
        options:[
            "CDMX", "Monterrey", "Guadalalajara", "Querétaro", "Puebla", "Morelos", "Guerrero", "Leon", "Oaxaca", "Michoacán", "Francia", "Italia"
        ],
        name: "stateResidence"
    },
    q6:{
        question: "¿Cuándo lo rescataste?",
        type: "date"
    },
    q7:{
        question: "Por favor sube por lo menos 3 imágenes. (máximo 5)",
        type: "file",
        quantity: 5
    },
    countries: ["México", "Argentina", "Canada", "Estados Unidos", "Venezuela", "Colombia", "Honduras", "Chile", "España", "Suiza", "Francia", "Italia"],
    cities: ["CDMX", "Monterrey", "Guadalalajara", "Querétaro", "Puebla", "Morelos", "Guerrero", "Leon", "Oaxaca", "Michoacán", "Francia", "Italia"]
};