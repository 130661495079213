import ReactModal from 'react-modal';
import { useState } from "react";
import { uploadPawFormSpanish } from '../../assets/constants/UploadPawForm';
import MyDatePickerImpl from '../../components/MyDatePickerImpl';
import MyDatePickerImpl2 from '../../components/MyDatePickerImpl2';
import { rescuesService } from '../../services/User/RescueService';
import { showToast } from '../../redux/slices/toastSlice';
import { useDispatch } from 'react-redux';
import PawThumbnail from '../paw/PawThumbnail';
import {RescueFetchData} from '../../hooks/rescuePawHook';
import { useEffect } from "react";



export default function UserRescues({uid}){
    
    const {rescueInfo, loading, error} = RescueFetchData.FetchDataUser(uid);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [files, setFiles] = useState();
    const [petName, setPetName] = useState();
    const [petDescription, setPetDescription] = useState();
    const [country, setCountry] = useState();
    const [rescueDate, setRescueDate] = useState(new Date());
    const [petBirthDate, setPetBirthDate] = useState(new Date());
    const [state, setState] = useState();
    const dispatch = useDispatch();
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    const [activePaws, setActivePaws] = useState();

    const isOwner = true;
    const triggerToastActionFail = (message) => {
        dispatch(showToast({message: message, type: "error"}));
    }

    const triggerToastActionSuccess = (message) => {
        dispatch(showToast({ message: message, type:"success"}));
    }
    

    const handleDateChange = (date) => {
        setSelectedDate(date);
        //setUserInfo(prevUserInfo => ({ ...prevUserInfo, birthDate: date}));
    }

    const submitAction = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('userId', uid);
        formData.append('pawName', petName);
        formData.append('pawDesc', petDescription);
        formData.append('pawBirth', petBirthDate);
        formData.append('residenceCountry', country);
        formData.append('residenceCity', state);
        formData.append('arrivedOn', rescueDate);
        files.forEach( (el, idx) => {
            formData.append(`image_${idx}`, el);
        });

        try{
            const res = await rescuesService.createEntry(formData);
            if(res.status === 200){
                triggerToastActionSuccess(res.data);
                cleanValues();
                setModalOpen(false);
                updateRescues();
            }else{
                triggerToastActionFail(res.data);
            }
        }catch(e){
            console.error(e);
        }
    }

    const handleFilesChange = (e) => {
        e.preventDefault();

        const selectedFiles = Array.from(e.target.files).slice(0, 5);
        setFiles(selectedFiles);
    }

    const updateRescues = async () => {
        try{
            const res = await rescuesService.fetchUpdate(uid);
            if(res.status === 200){
                setActivePaws(res.data);
            }
        }catch(e){
            triggerToastActionFail("Obtained failed");
        }
    }

    const cleanValues = () => {
        setCountry(null);
        setFiles(null);
        setPetBirthDate(null);
        setPetName(null);
        setPetDescription(null);
        setState(null);
    }

    const renderRescueInfo = () => {
        if(rescueInfo.length > 0){
        return (
        <div className="flex overflow-x-scroll p-2 ">
            {rescueInfo.map((item) => (
                <PawThumbnail pawObj={item} descToggle={true}></PawThumbnail>
            ))}
        </div>)
        }
        else
            return <div className="flex border-2 bg-white p-2 rounded-xl border-blue-200 justify-center"> 
                <span className="text-lg font-bold">No rescues submitted by this user...</span> 
            </div>   
    }

    useEffect(() => {
        if(rescueInfo){
            setActivePaws(rescueInfo);
        }
    }, [rescueInfo], selectedDate);

    if (loading) {return <div className="flex w-full items-center justify-center"><img src="/images/Iphone-spinner-2.gif"/></div>}
    if (error) {return <div>Error Ocurred . . .</div>}

    return(
        <div className="border-t-2 border-t-slate-200 p-3 mt-3 flex flex-col justify-center">
            <h2 className="text-2xl font-semibold mt-5">Rescues</h2>
            { isOwner
            ? <div><button onClick={openModal}> Add new Rescue </button></div> 
            : null
            }
            {renderRescueInfo()}
        <ReactModal
            isOpen={modalOpen}
            onRequestclose={closeModal}
            contentLabel="Register a new Dog/Pet/Paw"
        >
            <div className="flex w-full flex-col justify-center items-center h-full">

            <h2 className="text-xl w-full font-bold">Formulario de Rescate</h2>  
            <button className="w-full text-end font-bold text-xl" onClick={closeModal}>X</button>  
                <form className="w-full" onSubmit={submitAction} >
                    <section>
                            <div className="w-full grow mx-2 my-4">
                                    <div className="my-4">
                                        <label className="font-bold ">Nombre de la mascota:</label>
                                        <div className="flex bg-white min-w-[200px] p-2 items-center justify-center border-2 border-gray-200 rounded-md focus:outline-red-500">
                                            <input name="text" onChange={(e) => setPetName(e.target.value)} className="w-full focus:outline-none mx-3" type="text" placeholder="..." />
                                        </div>
                                    </div>

                                    <div className="my-4">
                                        <label className="font-bold ">Por favor escribe una breve descripción o historia de {petName}</label>
                                        <div class="mt-2">
                                            <textarea value={petDescription} onChange={(e) => setPetDescription(e.target.value)} id="intro" name="intro" rows="3" className="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                        </div>
                                    </div>
                                    
                                    <div className="my-4">
                                        <label className="font-bold">¿Cuál es la edad aproximada de {petName}?</label>
                                        <div className="flex items-start flex-col justify-start p-2 border-2 border-gray-200 rounded-md">
                                            <MyDatePickerImpl2
                                                selectedDate={selectedDate}
                                                onDatechange={handleDateChange}
                                            />
                                        </div> 
                                    </div>

                                    <div className="my-4">
                                        <label className="font-bold">¿Cuándo rescataste a {petName}?</label>
                                        <div className="flex items-start flex-col justify-start p-2 border-2 border-gray-200 rounded-md">
                                            <MyDatePickerImpl2
                                                selectedDate={selectedDate}
                                                onDatechange={handleDateChange}
                                            />
                                        </div>
                                    </div>

                                <div className="flex flex-col w-full justify-start items-start bg-white min-w-[200px] p-2 border-2 border-gray-200 rounded-md mt-4">
                                    <label className="font-bold">¿En qué país vives?</label>
                                    <select onChange={(e) => setCountry(e.target.value)} name="{q.name}">
                                        <option>Selecciona un país...</option>
                                        {uploadPawFormSpanish.countries.map((item) => (
                                            <option value={item}>{item}</option>
                                        ))}
                                    </select>
                                </div>
                                
                                <div className="flex flex-col w-full justify-start items-start bg-white min-w-[200px] p-2 border-2 border-gray-200 rounded-md mt-4">
                                <label className="font-bold">¿En qué ciudad vives?</label>
                                    <select onChange={(e) => setState(e.target.value)} name="{q.name}">
                                    <option>Selecciona una ciudad...</option>
                                        {uploadPawFormSpanish.cities.map(item => (
                                            <option key="" value={item}>{item}</option>
                                        ))};
                                    </select>
                                </div>

                                <div className="my-4">
                                    <label className="font-bold">Carga por favor un máximo de 5 imágenes:</label>
                                    <div className="mt-4 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                        <div className="text-center">
                                        <svg className="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />
                                        </svg>
                                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                            <label for="file-upload" className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                                            <span>Elige tus imágenes</span>
                                            <input id="file-upload" name="file-upload" multiple type="file" onChange={handleFilesChange} className="sr-only"/>
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF máximo 10MB</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>
                <section>
                    <div className="flex flex-row justify-around items-center w-full">
                            <input className="px-5 py-2 text-md font-bold text-white bg-red-700 rounded-xl border-2 border-red-700 hover:bg-red-800 ease-in-out duration-300 cursor-pointer mt-4" onClick={closeModal} type="button" value="Cancelar"/>
                            <input className="px-5 py-2 text-md font-bold text-white bg-blue-500 rounded-xl border-2 border-blue-500 hover:bg-blue-700 ease-in-out duration-300 cursor-pointer mt-4" type="submit" value="Guardar"/>
                    </div>
                </section>
            </form>
        </div>
        </ReactModal>   
        </div>
    )
}